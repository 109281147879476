/* Apply global styles more selectively */
body, html {
  font-family: 'Dancing Script', 'Pacifico', 'Great Vibes', 'Satisfy', 'Cookie', 'Allura', cursive;
  color: black;
}

.container {
  max-width: 100%;
}

.my-masonry-grid {
  display: flex;
  width: 100%;
}

.my-masonry-grid_column {
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 16px;
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.video-carousel {
  max-width: 100%;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(116, 177, 214);
  color: rgb(0, 0, 0);
  border-radius: 50px;
  padding: 15px 20px;
  font-size: 16px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-button:hover {
  background-color: rgb(73, 110, 133);
}

.floating-button svg {
  margin-right: 10px;
}

/* Titles - h2 */
h2 {
  font-size: 7vh;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 15px 30px;
  border-radius: 50px;
  backdrop-filter: blur(5px);
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  h2 {
    font-size: 5vh; /* Smaller font size for mobile devices */
    padding: 10px 20px;
  }

  .carousel-wrapper {
    height: 50vh; /* Adjust carousel height for smaller screens */
  }
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 60vh; /* Ensure the image does not take too much vertical space */
}

.round {
  border-radius: 10px;
}

.spaced {
  margin-bottom: 200px;
}

/* Add this CSS to styles.css */

.video-carousel video {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  backdrop-filter: blur(5px);
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

p {
  font-size: 3vh;
  font-weight: bold;
  margin: 20px 0;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 7vw 7vw;
  border-radius: 50px;
  backdrop-filter: blur(8px);
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}